import { checkoutEndpoints } from '@redux/features/checkout/api'
import type {
  ICheckoutState,
  SelectedDataType,
  TimetableSearchForm,
} from '@redux/features/checkout/types/checkoutState'
import { OrderBySort } from '@redux/features/checkout/types/sort'
import { TimetableFieldSort } from '@redux/features/checkout/types/sort'
import { getFirstTrainData } from '@redux/features/checkout/utils/getFirstTrainData'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice, isAnyOf } from '@reduxjs/toolkit'

import type { PassengersCategoryType } from '@Types/common/passengers'
import type { PassengersCountByCategories } from '@Types/passenger/passengersCategories'
import type { IRoute } from '@Types/routes/route'
import type { ExtraServicesFormType } from '@components/passengers/ExtraServices'
import type { PassengersFormReduxType } from '@components/passengers/types/passengersForms'
import type { PaymentFormType } from '@utils/forms/payment/types'
import getFormValuesForRedux from '@utils/forms/payment/utils/getFormValuesForRedux'

const initialState: ICheckoutState = {
  currentLeg: 1,
  extraServices: {
    collapseActiveKeys: [],
    extraServicesForm: {},
    loadingList: {},
  },
  passengers: {},
  payment: {
    isReadyToPay: false,
  },
  timetable: {
    searchForms: [],
    sort: {
      field: TimetableFieldSort.DEPARTURE,
      orderBy: OrderBySort.ASC,
    },
  },
  totalLegs: 1,
}

export const checkoutSlice = createSlice({
  extraReducers: builder => {
    builder
      .addMatcher(checkoutEndpoints.postBooking.matchFulfilled, (state, { payload }) => {
        state.booking = payload
        if (payload.passenger_allowed_after) {
          state.payment.isReadyToPay = true
        }
      })
      .addMatcher(
        isAnyOf(
          checkoutEndpoints.setBookingCurrency.matchFulfilled,
          checkoutEndpoints.postBookingService.matchFulfilled,
          checkoutEndpoints.postVoucher.matchFulfilled
        ),
        (state, { payload }) => {
          if (state.booking) state.booking.price = payload.total_price
          if (state.prebooking) state.prebooking.price = payload.total_price
        }
      )
      .addMatcher(
        isAnyOf(checkoutEndpoints.postSearch.matchFulfilled, checkoutEndpoints.postSearchBatch.matchFulfilled),
        (state, { meta: { arg }, payload: { departure_date, origin_departure_date, search_session_id, trains } }) => {
          state.timetable.departureDate = departure_date
          state.timetable.originalDepartureDate = origin_departure_date
          state.timetable.searchSessionId = search_session_id

          if (!state.timetable.selectedData && Object.keys(trains).length && arg.originalArgs.needSelectFirstTrains) {
            state.timetable.selectedData = getFirstTrainData(trains)
          }
        }
      )
      .addMatcher(checkoutEndpoints.postPassengers.matchFulfilled, (state, { meta }) => {
        state.passengers.postedPassengersCount = Object.entries(meta.arg.originalArgs.passengersForm).reduce(
          (acc, [category, categoryPassengers]) => {
            acc[category as PassengersCategoryType] = categoryPassengers.length
            return acc
          },
          {} as PassengersCountByCategories
        )
        state.payment.isReadyToPay = true
      })
      .addMatcher(checkoutEndpoints.postSearch.matchPending, state => {
        state.timetable.route = initialState.timetable.route
      })
      .addMatcher(checkoutEndpoints.postPreBooking.matchFulfilled, (state, { payload }) => {
        state.prebooking = payload
      })
  },
  initialState,
  name: 'checkout',
  reducers: {
    cleanBooking: state => {
      state.booking = initialState.booking
      state.prebooking = initialState.prebooking
    },
    cleanCheckoutOnNewSearch: state => {
      state.passengers = initialState.passengers
      state.extraServices = initialState.extraServices
      state.payment = initialState.payment
      state.prebooking = initialState.prebooking
      state.timetable.departureDate = initialState.timetable.departureDate
      state.timetable.originalDepartureDate = initialState.timetable.originalDepartureDate
      state.timetable.selectedData = initialState.timetable.selectedData
      state.timetable.route = initialState.timetable.route
    },
    clearCheckoutState: () => initialState,
    clearSelectedData: state => {
      state.timetable.selectedData = initialState.timetable.selectedData
    },
    clearTimetableRoute: state => {
      state.timetable.route = initialState.timetable.route
    },
    raiseCurrentLeg: state => {
      if (state.currentLeg < state.totalLegs) {
        state.currentLeg++
        delete state.timetable.searchSessionId
        state.timetable.selectedData = initialState.timetable.selectedData
        state.timetable.cheapestTrainNumber = initialState.timetable.cheapestTrainNumber
      }
    },
    setCheapestTrainNumber: (state, action: PayloadAction<ICheckoutState['timetable']['cheapestTrainNumber']>) => {
      state.timetable.cheapestTrainNumber = action.payload
    },
    setExtraServicesCollapseActiveKey: (state, action: PayloadAction<string[]>) => {
      state.extraServices.collapseActiveKeys = action.payload
    },
    setExtraServicesForm: (state, action: PayloadAction<ExtraServicesFormType>) => {
      state.extraServices.extraServicesForm = action.payload
    },
    setExtraServicesLoadingForm: (state, action: PayloadAction<{ isLoading: boolean; keyForm: string }>) => {
      const { isLoading, keyForm } = action.payload
      state.extraServices.loadingList = {
        ...state.extraServices.loadingList,
        [keyForm]: isLoading,
      }
    },
    setIsReadyToPay: (state, action: PayloadAction<boolean>) => {
      state.payment.isReadyToPay = action.payload
    },
    setOnlySearchForms: (state, action: PayloadAction<TimetableSearchForm[]>) => {
      state.timetable.searchForms = action.payload
      delete state.timetable.searchSessionId
    },
    setPassengersForm: (state, action: PayloadAction<PassengersFormReduxType>) => {
      state.passengers.passengersForm = action.payload
    },
    setPaymentForm: (state, action: PayloadAction<Partial<PaymentFormType>>) => {
      state.payment.paymentForm = getFormValuesForRedux(action.payload)
    },
    setQueryParams: (state, action: PayloadAction<string>) => {
      state.queryParams = action.payload
    },
    setSearchForms: (state, action: PayloadAction<TimetableSearchForm[]>) => {
      state.timetable.searchForms = action.payload
      state.totalLegs = action.payload.length
      state.currentLeg = 1
      delete state.timetable.searchSessionId
    },
    setSearchSessionId: (state, action: PayloadAction<string | undefined>) => {
      if (action.payload) {
        state.timetable.searchSessionId = action.payload
      } else {
        delete state.timetable.searchSessionId
      }
    },
    setSelectedData: (state, action: PayloadAction<Partial<SelectedDataType>>) => {
      const selectedData = { ...state.timetable.selectedData, ...action.payload } as SelectedDataType
      state.timetable.selectedData = {
        ...selectedData,
        train: {
          ...selectedData.train,
          train_number: selectedData.train.train_number || selectedData.coachClass?.train_number,
        },
      } as SelectedDataType
    },
    setTimeTableSort: (state, action: PayloadAction<{ field: TimetableFieldSort; orderBy: OrderBySort }>) => {
      state.timetable.sort = action.payload
    },
    updateRoute: (state, { payload }: PayloadAction<IRoute>) => {
      state.timetable.route = {
        ...state.timetable.route,
        ...payload,
        message: payload.message || state.timetable.route?.message || null,
        trains: { ...state.timetable.route?.trains, ...payload.trains },
      }
    },
  },
})

export const {
  cleanBooking,
  cleanCheckoutOnNewSearch,
  clearCheckoutState,
  clearSelectedData,
  clearTimetableRoute,
  raiseCurrentLeg,
  setCheapestTrainNumber,
  setExtraServicesCollapseActiveKey,
  setExtraServicesForm,
  setExtraServicesLoadingForm,
  setIsReadyToPay,
  setOnlySearchForms,
  setPassengersForm,
  setPaymentForm,
  setQueryParams,
  setSearchForms,
  setSearchSessionId,
  setSelectedData,
  setTimeTableSort,
  updateRoute,
} = checkoutSlice.actions

export default checkoutSlice.reducer
