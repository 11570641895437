export enum TagTypes {
  BOOKING_ORDER_INFO = 'bookingOrderInfo',
  GET_BOOKING_SERVICE = 'getBookingService',
  GET_ORDER = 'getOrder',
  POST_LOGIN = 'postLogin',
  POST_LOGOUT = 'postLogout',
  POST_PASSENGERS = 'postPassengers',
  TOTAL_PRICE = 'totalPrice',
  VOUCHER = 'voucher',
}
