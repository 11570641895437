import { PaymentFormFields } from '@constants/payment/paymentFormFields'

export const basicRequiredFieldsHST = [PaymentFormFields.EMAIL, PaymentFormFields.PHONE_NUMBER]
export const basicRequiredFields = [PaymentFormFields.PHONE_NUMBER]
export const basicRequiredFieldsForZeroTotalPrice = [PaymentFormFields.PHONE_NUMBER]

export const creditCardRequiredFields = [
  PaymentFormFields.CARD_NUMBER,
  PaymentFormFields.CARD_TYPE,
  PaymentFormFields.NAME_ON_CARD,
  PaymentFormFields.CARD_EXPIRATION_DATE,
  PaymentFormFields.SECRET_CODE,
  PaymentFormFields.COUNTRY,
]

export const appleGooglePayRequiredFields = [PaymentFormFields.NONCE]
