import type { ResponseRegistrationType } from '@redux/features/authorization/types/registartion'
import { setNotification } from '@redux/features/modals/modals.slice'
import type { RootState } from '@redux/store'
import { createAsyncThunk } from '@reduxjs/toolkit'
import type { TypedMutationTrigger } from '@reduxjs/toolkit/dist/query/react'
import type { BaseQueryFn, FetchArgs, FetchBaseQueryMeta } from '@reduxjs/toolkit/query'

import type { ApiResponseErrorType } from '@Types/api/responseError'
import type { ResponseErrorType } from '@Types/common/responseError'
import type { RegistrationFormType } from '@utils/forms/authorization/registration'
import { RegistrationFieldsName } from '@utils/forms/authorization/registration'

export type PostRegistrationType = TypedMutationTrigger<
  ResponseRegistrationType,
  RegistrationFormType,
  BaseQueryFn<FetchArgs | string, unknown, ApiResponseErrorType, NonNullable<unknown>, FetchBaseQueryMeta>
>

export default createAsyncThunk<
  void,
  {
    data: RegistrationFormType
    postRegistration: PostRegistrationType
  },
  { state: RootState }
>('authorization/registration', ({ data, postRegistration }, { dispatch }) => {
  const nameParts = data.full_name?.trim().split(' ') || []
  const firstName = nameParts[0]
  nameParts.splice(0, 1)
  const lastName = nameParts.join(' ').trim()
  const newData = {
    ...data,
    [RegistrationFieldsName.FirstName]: firstName,
    [RegistrationFieldsName.LastName]: lastName,
  }

  postRegistration(newData)
    .unwrap()
    .then(({ login_data, message, success }) => {
      if (success) {
        window.history.pushState({}, '', `/user/${login_data.current_user.uid}/?check_logged_in=1`)
        window.location.reload()
      } else {
        dispatch(
          setNotification({
            description: message,
            message: 'somethingWrong',
            type: 'error',
          })
        )
      }
    })
    .catch((error: ResponseErrorType) => {
      dispatch(
        setNotification({
          description: error.data?.message,
          message: 'somethingWrong',
          type: 'error',
        })
      )
    })
})
