import { internalApiPrefixV3, urlVersionPrefix } from '@constants/api/api'

export default {
  // /api/v3/internal/experiments/assignment - make experiment and get current experiment
  experimentAssignment: `${internalApiPrefixV3}/experiments/assignment`,

  // /api/v3/internal/experiments - get all experiments
  getExperimentInfo: `${internalApiPrefixV3}/experiments`,

  // /api/v1/site/info
  getSiteInfo: `${urlVersionPrefix}site/info`,

  // /api/currency/switch/${currency}
  switchCurrency: (currency: string) => `/currency/switch/${currency}`,
}
