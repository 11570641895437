export enum PaymentPayloadFields {
  ADDRESS = 'address',
  ADMINISTRATIVE_AREA = 'administrativeArea',
  CARD_NUMBER = 'cardNumber',
  CARD_TYPE = 'cardType',
  CITY = 'city',
  COUNTRY = 'country',
  EMAIL = 'email',
  EXPIRATION_MONTH = 'expirationMonth',
  EXPIRATION_YEAR = 'expirationYear',
  NAME_ON_CARD = 'nameOnCard',
  NONCE = 'nonce',
  PAYMENT_INTENT_ID = 'paymentIntentId',
  PAYMENT_METHOD = 'paymentMethod',
  PHONE_NUMBER = 'phoneNumber',
  POSTAL_CODE = 'postalCode',
  RETURN_URL = 'returnUrl',
  SCREEN_HEIGHT = 'screen_height',
  SCREEN_WIDTH = 'screen_width',
  SECRET_CODE = 'secretCode',
  SEON_SESSION_PAYLOAD = 'seonSessionPayload',
}
